<template>
  <div>
    <base-table ref="tableRef"
                :columnsData="columns"
                rowKey="id"
                :tableData="tableData">
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">收银系统</h4>
        </div>
      </template>
    </base-table>

  </div>
</template>

<script>
import {
  getList,
} from "@/api/system/cashier.js"

export default {
  components: {  },
  data () {
    return {
      columns: [
        {
          title: "ID",
          dataIndex: "id",
          align: "center",
        },
        {
          title: "收银软件",
          dataIndex: "name",
          align: "center",
        },
      ],

      activeId: '',
      tableData: [],
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    async initData () {
      const { data, code } = await getList()
      if (code === 0) {
        this.tableData = data.list
      }
    },
  },
}
</script>

<style>
</style>